import { BehaviorStore } from '@proscom/prostore';
//import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';
import { isBefore, isValid } from 'date-fns';
import { SubscriptionManager } from '../utils/SubscriptionManager';
import {
  MUTATION_LOGIN_WITH_EMAIL,
  MUTATION_LOGOUT,
  MUTATION_USE_REFRESH_TOKEN
} from '../graphql/mutations/auth';
import { singleton } from '../utils/singleton';
import { rnMessenger } from '../rnMessenger';
import { NativeMethods } from '../utils/ReactNativeMessenger';
import { SingleTimeoutManager } from '../utils/SingleTimeoutManager';
import { tryParseIso } from '../utils/date';
import { LOCAL_STORAGE_AUTH } from './LocalStorageStore';

export const userRoles = {
  REGION_ADMIN: 'REGION_ADMIN',
  FEDERAL_ADMIN: 'FEDERAL_ADMIN',
  ADMIN: 'ADMIN'
};

const masterRoles = new Set([userRoles.FEDERAL_ADMIN, userRoles.ADMIN]);

export const ERROR_REFRESH_TOKEN_INVALID =
  'Сессия истекла. Пожалуйста, войдите заново';

const clearState = {
  accessToken: null,
  refreshToken: null,
  user: null
};

export class AuthStore extends BehaviorStore {
  localStorageStore;
  client;
  sub = new SubscriptionManager();
  refreshTimeout = new SingleTimeoutManager();

  constructor({ localStorageStore, client }) {
    super({
      authData: null,
      loaded: false,
      error: null
    });

    this.localStorageStore = localStorageStore;
    this.client = client;
  }

  subscribe() {
    if (rnMessenger.isActive) {
      rnMessenger
        .call(NativeMethods.loadAuthData)
        .then((authData) => {
          this._handleLocalStorageChange(authData);
        })
        .catch((error) => {
          this._setError(error);
        });
    } else {
      this.sub.subscribe(
        this.localStorageStore.get$(LOCAL_STORAGE_AUTH),
        this._handleLocalStorageChange,
        this._setError
      );
    }
    this.sub.subscribe(this.state$, this._handleStateChange);
  }

  unsubscribe() {
    this.sub.destroy();
    this.refreshTimeout.clear();
  }

  _handleStateChange = (state) => {
    const { authData } = state;
    if (authData && authData.refreshToken && authData.refreshToken.expires_at) {
      const expiresAt = tryParseIso(authData.refreshToken.expires_at);
      if (expiresAt) {
        const timeout = Math.max(expiresAt - new Date() - 60000, 0);
        this.refreshTimeout.set(() => {
          this.refreshToken();
        }, timeout);
      } else {
        this.refreshTimeout.clear();
      }
    } else {
      this.refreshTimeout.clear();
    }
  };

  _handleLocalStorageChange = (authData) => {
    if (!isEqual(authData, this.state.authData)) {
      if (authData && isRefreshTokenValid(authData.refreshToken)) {
        this.setState({
          authData
        });
      } else if (this.state.loaded) {
        this._setError(ERROR_REFRESH_TOKEN_INVALID);
      } else {
        this.setState({
          authData: null
        });
      }
    }

    if (!this.state.loaded) {
      this.setState({
        loaded: true
      });
    }
  };

  _saveToLocalStorage(authData) {
    if (rnMessenger.isActive) {
      rnMessenger
        .call(NativeMethods.saveAuthData, authData)
        .catch((error) => console.error(error));
    } else {
      this.localStorageStore.setItem(LOCAL_STORAGE_AUTH, {
        accessToken: authData.accessToken,
        refreshToken: authData.refreshToken,
        user: authData.user
      });
    }
  }

  _setAuthenticationData(authData) {
    this.setState({ authData });
    this._saveToLocalStorage(authData);
  }

  _setError(error) {
    this._setAuthenticationData(clearState);
    this.setState({
      error,
      loaded: true
    });
  }

  async logOut() {
    try {
      const { authData } = this.state;
      const token =
        authData && authData.refreshToken && authData.refreshToken.token;
      let result = null;

      if (token) {
        result = await this.client.mutate({
          mutation: MUTATION_LOGOUT,
          variables: {
            token
          }
        });
      }

      return result;
    } catch (error) {
      return { error };
    } finally {
      this._setAuthenticationData(clearState);
      window.location.reload();
    }
  }

  loginWithEmail = async (email, password) => {
    const result = await this.client.mutate({
      mutation: MUTATION_LOGIN_WITH_EMAIL,
      variables: {
        email,
        password
      }
    });
    if (result.error) {
      throw result;
    }
    const authData = result.data.authData;
    this._setAuthenticationData(authData);
    return authData;
  };

  async _useRefreshToken(token) {
    try {
      const result = await this.client.mutate({
        mutation: MUTATION_USE_REFRESH_TOKEN,
        variables: {
          token
        }
      });

      const authData = result.data.authData;
      this._setAuthenticationData(authData);
      return authData;
    } catch (error) {
      this._setError(error);
      return null;
    }
  }

  async _performTokenRefresh() {
    const { refreshToken } = this.state.authData || {};

    if (!isRefreshTokenValid(refreshToken)) {
      this._setError(ERROR_REFRESH_TOKEN_INVALID);
      return;
    }

    return await this._useRefreshToken(refreshToken.token);
  }

  refreshToken = singleton(() => this._performTokenRefresh());

  isRefreshingToken() {
    return !!this.refreshToken.promise;
  }

  canRefreshToken() {
    const { authData } = this.state;
    return isRefreshTokenValid(authData && authData.refreshToken);
  }

  isLoggedIn() {
    const { authData } = this.state;
    return authData && authData.accessToken;
  }

  isSuperUser() {
    const { authData } = this.state;
    const userRole = authData && authData.user && authData.user.role;
    return masterRoles.has(userRole);
  }
}

/**
 * Проверка устаревания долгосрочного токена
 */
export function isRefreshTokenValid(refreshToken) {
  if (!refreshToken || !refreshToken.token || !refreshToken.expires_at) {
    return false;
  }
  const expirationDate = tryParseIso(refreshToken.expires_at);
  return isValid(expirationDate) && isBefore(new Date(), expirationDate);
}
